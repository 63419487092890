.container-footer {
  background: linear-gradient(
    90deg,
    rgba(253, 175, 200, 0.4) 0%,
    rgba(255, 255, 255, 0.4) 140%
  );
  background-size: cover;
  width: 100%;
  position: relative;
}

.footer-social {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-social a {
  color: black;
  font-size: 2.5rem;
  text-decoration: none;
  margin: 0 20px;
}

.instagram {
  font-size: 1.2rem;
  margin-left: 10px;
}

.footer-logo {
  width: 30%;
  height: auto;
  margin-left: 5%;
}

.contact {
  border: 2px solid transparent;
  border-bottom-color: white;
  margin-top: 20px;
  text-align: center;
  color: black;
  margin-bottom: 20px;
}


.contact a {
  color: black;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .footer-logo {
    width: 50%;
    margin-left: 0;
  }
  .footer-social {
    flex-direction: column;
    align-items: center;
  }
    .footer-social a {
        margin: 10px 0;
        font-size: 1.8rem;
    }
  .contact {
    margin-top: 10px;
  }
  .contact p {
    margin-left: 0;
    text-align: center;
  }
}
