.social-media-submenu {
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.toggle-button {
  background-color: #fff;
  color: #000;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 2rem;
  border: 2px solid rgba(253, 151, 136);
}

.submenu {
  position: absolute;
  bottom: 80px;
  right: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.3s, transform 0.3s;
  z-index: -1;
}

.submenu.open {
  opacity: 1;
  transform: translateY(0);
  z-index: 1;
  transition: opacity 0.3s, transform 0.3s;
}

.submenu li {
  margin: 10px 0;
}

.submenu a {
  color: rgba(253, 151, 136);
  text-decoration: none;
  font-size: 1.5rem;
  background-color: #fff;
  padding: 12px;
  border-radius: 50%;
  display: flex;
  border: 2px solid rgba(253, 151, 136);
}

.toggle-button:hover {
  color: #f9b5d0;
  transform: scale(1.1);
  transition: transform 0.3s;
}

.submenu a:hover {
  background-color: #fff;
  color: #000;
  transform: scale(1.1);
  transition: transform 0.3s;
}

.toggle-button.open {
  transform: rotate(45deg);
  transition: transform 0.3s;
}

.logo-submenu {
  width: 40px;
  height: auto;
}

@media (max-width: 768px) {
  .social-media-submenu {
    bottom: 20px;
    right: 20px;
  }
}