html,
body {
  height: 100%;
  margin: 0;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container-cm {
  flex: 1; /* Grow to fill remaining space */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

/* h1 of containers */
.container-cm h1 {
  font-size: 2rem;
  font-weight: 700;
  color: #000000;
}

.container-cm p {
  font-size: 1rem;
  font-weight: 400;
  color: #000000;
}

.text-center-cm {
  text-align: center;
}

.logo-cm {
  width: 300px;
  height: auto;
}

.iframe-map {
  border: 0;
  width: 90%; /* Adjust map width for smaller screens */
  height: 300px; /* Adjust map height for smaller screens */
}

.footer {
  margin-top: auto; /* Push the footer to the bottom */
}
@media (max-width: 768px) {
  .container-cm h1 {
    font-size: 1.5rem; /* Further reduce font size for smaller screens */
  }

  .logo-cm {
    width: 200px; /* Further reduce logo size for smaller screens */
  }

  .iframe-map {
    width: 90%; /* Further adjust map width for smaller screens */
    height: 200px; /* Further adjust map height for smaller screens */
  }
}
