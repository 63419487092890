* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body , html{
  font-family:"Comfortaa", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: 80vh;
}

.h1-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 1rem 0;
  padding: 0.1rem;
  color: #F9B5D0;
}

article h1 {
  margin: 1rem 0;
}